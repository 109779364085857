* {
  font-family: "Montserrat", sans-serif;
  margin: 0px;
  padding: 0px;
}

p {
  line-height: 1.5;
  color: black;
  font-size: 20px;
  font-weight: 400;
}

h1 {
  text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
}

.subtext {
  font-size: 16px;
  font-weight: 600;
  color: rgba(80, 80, 80);
}

.nav-btns {
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-size: 16px;
}

.dualpics {
  box-shadow: 6px 4px 30px 10px rgba(0, 0, 0, 0.2);
}
.cta-text {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.cta-text:hover {
  transition: color 300ms cubic-bezier(0.37, 0.36, 0.67, 1.01);
  color: black;
}

.contact-btns {
  border: 1px solid black;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  padding: 8px;
  border-radius: 40px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  transition: color 500ms cubic-bezier(0.37, 0.36, 0.67, 1.01);
  transition: background-color 500ms cubic-bezier(0.37, 0.36, 0.67, 1.01);
}

.contact-btns:hover {
  transition: color 300ms cubic-bezier(0.37, 0.36, 0.67, 1.01);
  background-color: black;
  color: white;
}

.social-icons {
  cursor: pointer;
}

.animated-arrow {
  animation-duration: 1s;
  animation-name: pointing;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes pointing {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(20px);
  }
}

.review-enter {
  transform: translateX(-80%);
}
.review-enter-active {
  transform: translateX(0%);
  transition: transform 300ms;
}
.review-exit {
  transform: translateX(0%);
}
.review-exit-active {
  transform: translateX(60%);
  transition: transform 500ms;
}
